/*
 *
 * Variables
 *
 * Contains all the site variables to configure the components
 */

/***********
Media Queries
************/

$mobileM: 370px;
$phablet: 480px;
$tablet: 768px;
$netbook: 1100px;
$laptop: 1280px;
$desktop: 1650px;

/***********
Spacing
************/

$site-width-m: 85%;
$site-width-d: 85%;
$site-max-width: 1450px;
$site-max-width-narrow: 1120px;
$site-max-width-text-narrow: 720px;
$site-max-width-wide: 1600px;

$header-height-m: 70px;

$base-s: 5px;
$base-1: 10px;
$base-2: 20px;
$base-3: 30px;
$base-4: 40px;
$base-5: 60px;
$base-6: 80px;
$base-7: 100px;
$base-8: 120px;

$section-m: $base-4;
$section-d: $base-4 * 2;

/***********
Colors
************/

//brand colors
$hopp-dark-blue: #131730;
$hopp-pink: #FF78DE;
$hopp-light-blue: #E1F7FF;
$hopp-sky-blue: #22b1e0;
$hopp-orange: #ff852e;
$hopp-off-orange: #FFCEAB;
$hopp-green: #79ff1b;
$hopp-red: #ff2222;
$hopp-pale-orange: #FAF1E5;


//color list
$hopp-colors: $hopp-pink, $hopp-sky-blue, $hopp-orange;

//base
$black: #000;
$piano: #282828;
$white: #fff;
$off-blue: #1d2344;
$ocean-stone: #323a4e;
$grey-out: #888;
$faded-grey: #838795;
$light-grey: #f4f7fc;
$soft-grey: #aeb2c9;
$dark-grey: #7b86a7;

$white-rhino: #e5e6ec;
$shadow: transparentize($hopp-dark-blue, 0.7);

//error
$error-color: #ff2222;
$focus-color: #ccc;

/***********
Assign Colors
************/

//text
$light-text: $white; //used for when text is on a dark background
$dark-text: $hopp-dark-blue;
$grey-text: transparentize($hopp-dark-blue, 0.5);
$soft-grey-text: $soft-grey;

//backgrounds
$body-background: $white;
$grey-background: $light-grey;
$darkgrey-background: transparentize($hopp-dark-blue, 0.8);
$dark-background: $hopp-dark-blue;
$orange-background: $hopp-pale-orange;
$search-sidebar-background: $hopp-pale-orange;

//lines
$light-line: $white-rhino;
$dark-line: $ocean-stone;
$orange-line: transparentize(#ffdad1, 0.4);

// $grey-line: $soft-grey;
$greyer-line: #e0e0e0;
$darker-line: $off-blue;

//links
$link-text: $hopp-pink;

//features
$virtual: $hopp-sky-blue;
$virtual-pale: lighten($hopp-sky-blue, 46%);

/***********
Elevation (Box Shadows)
************/
$elevation-light: 0 4px 6px -5px $shadow;
$elevation-light-hover: 0px 2px 8px -4px rgba($hopp-dark-blue, 0.3);
$elevation-card: 0px 5px 10px -5px rgba($hopp-dark-blue, 0.3); 
$elevation-1: 0 3px 18px 2px transparentize($hopp-dark-blue, 0.95);
$elevation-2: 0 4px 10px -4px $shadow;
$elevation-3: 0 5px 15px -1px $shadow;
$elevation-3-right: -8px 0px 10px -4px rgba(19, 23, 48, 0.3);
$elevation-2-top: 0 -4px 4px rgba(0, 0, 0, 0.05);
$elevation-3-top: 0 -10px 10px rgba(0, 0, 0, 0.15);
$elevation-4-top: 0 -10px 10px rgba(80, 80, 80, 0.4);

$elevation-4: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
  0 -6px 16px -6px rgba(0, 0, 0, 0.025);
$elevation-4-hover: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3),
  0 -12px 36px -8px rgba(0, 0, 0, 0.025);

$fadedBlack: rgba(0, 0, 0, 0.58);
$elevation-4-main: 0 13px 27px -5px $fadedBlack, 0 8px 16px -8px $fadedBlack,
  0 -6px 16px -6px $fadedBlack;
$elevation-4-main-hover: 0 30px 60px -12px $fadedBlack, 0 18px 36px -18px $fadedBlack,
  0 -12px 36px -8px $fadedBlack;

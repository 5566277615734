/*
 * Name: Create Party
 * Desc:
 *
*/

@import '@styles/variables.scss';


.EditPartyModal {
    background: $grey-background;
    form {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        @media screen and (min-width:$netbook) {
            min-height: 0;
        }
        h2 {
            text-align: center;
            height: $header-height-m;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                display: block;
                width:100%;
            }
        }
        h3 {
            justify-content: center;
        }
        div {
            margin-bottom: $base-2;
        }
        p {
            max-width: 200px;
            margin: auto;
            font-weight: 500;
            font-size: 16px;
        }
    }
    .full {
        flex: 1;
    }
    .buttons {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: $base-1;
        column-gap: $base-1;
        padding-bottom: $base-3;
        margin:0;
        button {
            width:100%;
        }
    }
    .center {
        text-align: center;
    }

}
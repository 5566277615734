/*
 * Name: Button
 * Desc:
 *
*/

@import '@styles/variables.scss';

.SupplierSavedNotice {
    display: flex;
    position: fixed;
    z-index: -1;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    bottom: 10%;
    box-sizing: border-box;
    left:50%;
    transform: translateX(-50%);
    background: $hopp-pink;
    padding: $base-1;
    padding-right: $base-2*1.5;
    border-radius: 47px;
    align-items: center;
    justify-content: flex-start;
    box-shadow: $elevation-3;
    @media screen and (max-width:$phablet) {
        min-width:270px;
    }
    @media screen and (min-width:$netbook) {
        bottom: 15%;
    }
    img {
        width: 65px;
        height: 65px;
        object-fit: cover;
        border-radius: 100%;
    }
    .text {
        margin-left: $base-2;
        span {
            display: block;
            color: $light-text;
            font-size: 17px;
            font-weight: 500;
            &:first-child {
                font-weight: 400;
                margin-bottom: $base-s;
            }
        }
    }
    &.active {
        z-index: 5;
        opacity: 1;
    }

    &.removed {
        background: $grey-background;
        .text span {
            color: $dark-text;
        }
    }
}
/*
 * Name: Breadcrumbs
 * Desc:
 *
*/

@import "@styles/variables.scss";

.SupplierCard {
  position: relative;
  box-shadow: $elevation-card;
  display: flex;
  flex-direction: column;
  :global {
    .slick-slide {
      & > div {
        margin-left: 0px;
      }
    }
    .slick-list {
      margin-left: 0px;
    }
    .slick-arrow.slick-disabled {
      pointer-events: inherit;
    }
  }
  &:hover {
    .mainButtons button {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    }
  }
  .image {
    position: relative;
    height: 0px;
    padding-bottom: 75%;
    background: $light-grey;
    overflow: hidden;
    a {
      display: block;
    }
    > img,
    .photos {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &.isVirtual {
    .content {
      background: $virtual-pale;
      .mainInfo .category {
        color: $faded-grey;
      }
    }
  }
  .content {
    position: relative;
    flex: 1;
    padding: $base-2;
    background: $white;

    .mainInfo {
      .title {
        display: block;
        color: $dark-text;
        font-size: 17px;
        font-weight: 600;
        margin-bottom: $base-s;
      }
      .category,
      .price {
        color: $soft-grey-text;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        margin-right: $base-1;
      }
    }

    .detailInfo {
      margin: 15px 0 0;
      .icons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      .location {
        color: dark-text;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-right: $base-2;
        img {
          margin-right: 2px;
          width: 16px;
        }
        span {
          color: $dark-text;
          letter-spacing: 0.5px;
        }
      }
      .badges {
        display: flex;
        justify-content: center;

        span {
          height:20px;
          width: 20px;
          margin-right: $base-2;
          margin-bottom: $base-s;
        }
        .recommended {
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $dark-background;
          box-shadow: $elevation-2;
          img {
            width: 8px;
          }
        }
        .virtualIcon {
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $virtual;
          box-shadow: $elevation-2;
          img {
            width: 11px;
          }
        }
        .bundleIcon {
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $virtual;
          box-shadow: $elevation-2;
          img {
            width: 11px;
          }
        }
        .discount {
          display: flex;
          align-items: center;

          .discountImg {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background-color: $hopp-dark-blue;
            padding: 4px;
            img {
              width: 8px;
            }
          }
          &,
          span {
            width: auto;
            height: auto;
          }
          span {
            font-size: 12px;
            color: $hopp-orange;
            margin: 0 0 0 $base-s;
          }
        }
      }
    }

    .hoppNote {
      margin-top: 10px;
      p {
        color: $dark-text;
        margin: 0;
        font-size: 14px;
        letter-spacing: 0.5px;
        opacity: 0.85;
        line-height: 1.5;
      }
    }

    .editButtons {
      display: flex;
      > button {
        margin-right: $base-1;
      }
      button {
        margin-top: $base-1;
        &.Contacted {
          background: $hopp-orange;
          color: $light-text;
        }
        &.Confirmed {
          background: $hopp-sky-blue;
          color: $light-text;
        }
        &.noteText {
          background: $grey-background;
          text-transform: none;
          color: $dark-text;
          font-size: 13px;
          font-weight: 500;
          box-shadow: none;
          border-radius: 0px;
          &:hover {
            background: $grey-background;
          }
        }
      }
    }
  }
  .mainButtons {
    position: absolute;
    top: $base-1;
    right: $base-1;
    display: flex;
    flex-direction: column;
    margin-bottom: $base-1;
    button {
      padding: 7px;
      opacity: 1;
      margin-bottom: $base-1;
      img {
        display: block;
      }
      @media screen and (min-width: $tablet) {
        width: 100px;
      }
      @media screen and (min-width: $netbook) {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;

        &.showButton {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 300ms;
        }
      }
    }
  }

  &.isFeatured {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: $base-3;
    background: $hopp-light-blue;
    @media screen and (min-width: $netbook) {
      grid-template-columns: 50% 1fr;
    }
    .image {
      @media screen and (min-width: $netbook) {
        margin-bottom: 0;
      }
      img {
        border-radius: 0;
        display: table;
        margin-left: 1px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      padding: $base-3;

      border: none;
      background: none;
      @media screen and (min-width: $netbook) {
        padding: $base-3 0;
        padding-right: 100px;
      }
      .title {
        font-size: 26px;
      }
    }
    .mainButtons button {
      visibility: visible;
      opacity: 1;
      border: 1px solid $light-line;
      margin-bottom: $base-1;
      box-shadow: none;
    }
  }
}

.review {
  display: grid;
  grid-template-columns: 300px 1fr;
  column-gap: $base-3;
  align-items: flex-end;
}

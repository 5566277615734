/*
 * Name: Sign Up Header
 * Desc:
 *
*/

@import "@styles/variables.scss";

$skeleton-color: $light-line;
$shine-color: rgba($light-line, 0.7);

.grid,
.masonry,
.portrait {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $base-4;
  column-gap: $base-3;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: $netbook) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  // @media screen and (min-width: $laptop) {
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  // }
}

.list {
  > div {
    margin-bottom: $base-3;
    padding-bottom: $base-3;
    border-bottom: 1px solid $light-line;
    &:last-child {
      border: 0;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  .image {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    display: block;
    background-image: linear-gradient(90deg, $skeleton-color 0px, $shine-color 20%, $skeleton-color 25%);
    background-size: 1000px;
    animation: shimmer 2s infinite;
    height: 0px;
    padding-bottom: 75%;
  }

  .content {
    padding: $base-2;
    border: 1px solid $skeleton-color;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background: $white;
    // min-height: 140px;
    flex: 1;
    span {
      display: block;
      // background: $skeleton-color;
      background-image: linear-gradient(90deg, $skeleton-color 0px, $shine-color 40px, $skeleton-color 80px);
      background-size: 600px;
      animation: shimmer 3s infinite;
      &.title {
        width: 80%;
        max-width: 200px;
        margin-bottom: $base-s;
        height: 18px;
      }
      &.category {
        width: 40%;
        max-width: 100px;
        height: 14px;
      }
    }
    .text {
      margin-top: 20px;
      span {
        width: 95%;
        margin-bottom: $base-s;
        height: 9px;
        margin-bottom: 7px;
        &:nth-child(2),
        &:nth-child(4) {
          width: 85%;
        }
        &:nth-child(3) {
          width: 90%;
        }
      }
    }
  }
  @keyframes shimmer {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 140px;
    }
  }
}

.portrait .card {
  .image {
    padding-bottom: 130%;
  }
}

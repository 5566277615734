/*
 * Name: Enquire Supplier Modal
 * Desc:
 *
*/

@import "@styles/variables.scss";

.SupplierEnquireModal {

  .tabs {
    background: $grey-background;
    padding: $base-3 0;

    ul {
      display: flex;
      border-bottom: 1px solid $hopp-sky-blue;
      li, button {
          display: block;
      }
      li + li {
          margin-left: 1px;
      }
      button {
        padding: $base-1;
        font-size: 14px;
        text-transform: uppercase;
        background: rgba($hopp-sky-blue, 0.2);
        color: $grey-text;
        border: 1px solid $light-line;
        border-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        // font-weight: 500;
        cursor: pointer;
        outline: none;
        transition: background 100ms ease;
        &:hover {
            // border: 1px solid $hopp-sky-blue;
            border-bottom: 0;
            background: rgba($hopp-sky-blue, 0.3);
        }
        &.isActive {
            cursor: default;
            &, &:hover {
                border: 1px solid $hopp-sky-blue;
                background: $hopp-sky-blue;
                color: $white;
                border-bottom: 0;
            }
          
        }

      }
    }
  }
}



/*
 * Name: SupplierServicesBar
 * Desc:
 *
*/

@import "@styles/variables.scss";

.SupplierReviewForm {
    form > label {
        display: flex;
        align-items: center;
        span {
            color: red;
            font-size: 14px;
        }
        div {
            margin-left: $base-1;
            color: red;
        }
    }
    .stars {
        border: none;
        padding: 0;
        display: inline-block;
        &:hover {
            .star {
                color: $hopp-pink;
            }
        }
        .star {
            font-size: 1.5em;
            color: #ABABAB;
            transition: color .2s ease-out;
            cursor: pointer;
            &:hover {
                color: $hopp-pink;
                & ~ label {
                    color: #ABABAB;
                }
            }
            &.selected {
                color: $hopp-pink;
            }
        }
    }
    .written {
        margin-top: $base-2;
        textarea {
            border: 1px solid $light-line;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 0;
            max-width: 680px;
        }
    }
}

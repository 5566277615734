/*
 * Name: Create Party
 * Desc:
 *
*/

@import "@styles/variables.scss";

.EnquireForm {
  @media screen and (min-width: $netbook) {
    background: $grey-background;
  }

  form {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    min-height: 100vh;
    @media screen and (min-width: $netbook) {
      min-height: 0;
    }
    label {
      display: block;
      margin-bottom: 8px;
      span {
        color: red;
        font-size: 11px;
      }
    }
    h2 {
      text-transform: none;
      text-align: left;
      height: $header-height-m;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      span {
        display: block;
        width: 100%;
      }
    }
    h3 {
      justify-content: center;
      margin: 10px;
    }

    p {
      max-width: 200px;
      margin: auto;
      font-weight: 500;
      font-size: 16px;
    }
    input, textarea, select {
      padding: 13px 16px;
      line-height: 1.2;
    }

    textarea {
      margin: 0;
      min-height: 165px;
    }
    .reactSelect {
      input {
        margin:8px 0;
      }
    }

    :global {
      .PhoneInput {
        margin-top: $base-s;
        @media screen and (min-width: $netbook) {
          margin-top: 0;
        }
        background-color: $white;
        border: 1px solid transparent;
        border-radius: 3px;
        padding-left: $base-1;
        input {
          margin: 0;
          border: none;
          padding-left: $base-1;
        }
        &:focus {
          border-color: #ccc;
        }
      }
    }

    .checkbox {
      display: flex;
    }

    input[type="checkbox"] {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        user-select: none;
        padding: 0;
        font-size: 13px;
        display: block;
        line-height: 1.4;
      }

      // Box.
      & + label:before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        background: white;
        border: 1px solid $light-line;
        border-radius: 2px;
        position: relative;
        margin-right: 10px;
        display: inline-block;
        vertical-align: sub;
        transform: none;
        top: auto;
      }

      // Box checked
      &:checked + label:before {
        background: $hopp-sky-blue;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: "";
        position: absolute;
        right: 12px;
        top: 11px;
        background: white;
        width: 2px;
        height: 2px;
        left: 3px;
        top: 8px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
          4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
      }
    }
  }
  .full {
    flex: 1;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 15px;
    @media screen and (min-width: $netbook) {
      grid-template-columns: 1fr 1fr;
      .checkbox,
      .fullwidth {
        grid-column: span 2;
      }
    }
    > div {
      > input {
        margin: 0;
      }
    }
  }
  .fullwidth {
    margin-bottom: 10px;
    @media screen and (min-width: $netbook) {
      margin-bottom: 15px;
    }
    input {
      margin: 0;
    }
  }
  .buttons {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: $base-1*0.5;
    column-gap: $base-1;
    padding-bottom: $base-3;
    margin: 0;
    margin-top: $base-1;
    button {
      width: 100%;
      padding: 15px 24px;
    }
  }
  .contactDetailsButton {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    // text-transform: none;
    color: $hopp-dark-blue;
    opacity: 0.7;
    text-decoration: underline;
    &:hover {
      opacity: 1;
    }
  }
  .center {
    text-align: center;
  }

  .processing {
    animation: pulse 0.8s infinite;
  }
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
}

/*
 * Name: Enquire Thanks
 * Desc:
 *
*/

@import "@styles/variables.scss";

.EnquireThanks {
  background: $grey-background;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media screen and (min-width: $netbook) {
    min-height: 0;
    height: 712px; //form height
    padding: $base-4;
    box-sizing: border-box;
  }
  .animation {
    margin-top: -50px;
  }
  .text {
    transition: all 300ms ease-in-out;
    opacity: 0;
    transform: translateY(-90px);
    &.completed {
      opacity: 1;
    }
  }
  h2 {
    text-transform: none;
  }
  button {
    margin-left: auto;
    margin-right: auto;
  }
}

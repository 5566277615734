/*
 * Name: Button
 * Desc:
 *
*/

@import "@styles/variables.scss";

.SupplierList {


  .slider {
    overflow: hidden;
  }
  :global {
    .swiper-pagination {
      display: none;
    }
    .slick-track {
      margin-left: 0;
      display: flex;
    }

    .slick-slide {
      // height: inherit;
      display: flex;
      & > div {
        margin-left: 30px;
        margin-bottom: 10px;
        display: flex;
      }
    }
    .slick-list {
      margin-left: -30px;
    }
  }

  &.grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: $base-4;
    column-gap: $base-3;
    @media screen and (min-width: $tablet) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: $netbook) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    // @media screen and (min-width:$desktop) {
    //     grid-template-columns: 1fr 1fr 1fr 1fr;
    // }
  }
  &.list {
    > div {
      margin-bottom: $base-3;
      padding-bottom: $base-3;
      border-bottom: 1px solid $light-line;
      &:last-child {
        border: 0;
      }
    }
  }

  &.review {
    grid-template-columns: 1fr;
  }
}

.empty {
  // position: relative;
  :global .gatsby-image-wrapper {
    border-radius: 3px;
    box-shadow: $elevation-2;
  }
  img {
    width: 100%;
    object-fit: cover;
    @media screen and (max-width: $tablet) {
      height: 70vh;
    }
  }
  .image {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: $netbook) {
      justify-content: flex-start;
    }
  }
  .extra {
    p {
      color: $soft-grey;
      margin-top: $base-1;
      transition: color 300ms ease-in-out;
    }
    &:hover {
      p {
        color: $dark-text;
      }
    }
  }
  .text {
    color: $light-text;
    width: 100%;
    max-width: 500px;
    padding: $base-3;
    text-align: center;
    @media screen and (min-width: $netbook) {
      color: $dark-text;
      text-align: left;
      max-width: 400px;
      padding-left: 7%;
      a {
        border-color: $dark-text;
        color: $dark-text;
      }
    }
    h2 {
      text-transform: none;
      font-weight: 500;
      font-size: 32px;
      margin: 0 0 $base-1;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      margin: $base-2 0;
    }
    a {
      display: inline-block;
    }
  }
  &.searchPage {
    .overlay {
      justify-content: center;
    }
    .image {
      background: $light-grey;
    }
    .text {
      text-align: center;
      padding: $base-3;
      color: $dark-text;
      p {
        margin-top: 0;
        font-size: 19px;
      }
      button {
        margin: auto;
      }
    }
  }
}
